<template>
  <div
    @dragleave="onDragLeave"
    @dragend="onDragEnd"
    @drop="onDragDrop"
    @dragover="onDragOver"
    v-bind="$attrs"
    :class="{
      relative: !noRelative,
    }"
  >
    <slot v-bind="{ isDropOverlayVisible: debouncedShowDroppingView }" />
    <transition
      enter-active-class="transition-opacity"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="debouncedShowDroppingView"
        class="pointer-events-none select-none left-0 top-0 w-full h-full z-[9999]"
        :class="{
          fixed: overlayFixed,
          absolute: !overlayFixed,
        }"
      >
        <slot name="dropview">
          <div
            class="h-full px-2 bg-neutral-200 dark:bg-neutral-800 rounded-md flex flex-col justify-center items-center gap-2"
          >
            <IconDragDrop2 />
            <div class="text-center">Release to add file</div>
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { IconDragDrop2 } from "@tabler/icons-vue";
import { debouncedRef } from "@vueuse/core";

type MaybePromise<T> = T | Promise<T>;

const props = defineProps<{
  rules?: ((files: File[]) => MaybePromise<string | null>)[];
  noRelative?: boolean;
  disabled?: boolean;
  overlayFixed?: boolean;
}>();

const emit = defineEmits<{
  (e: "filesdropped", v: File[]): void;
}>();

defineComponent({
  inheritAttrs: false,
});

const nuxtApp = useNuxtApp();
const app = nuxtApp.$app();

const showDroppingView = ref(false);
const debouncedShowDroppingView = debouncedRef(showDroppingView, 10);

function onDragLeave() {
  if (props.disabled) return;
  showDroppingView.value = false;
}

function onDragEnd() {
  if (props.disabled) return;
  showDroppingView.value = false;
}

function onDragOver(event: DragEvent) {
  if (props.disabled) return;
  showDroppingView.value = true;
  event.preventDefault();
  event.stopImmediatePropagation();
  event.stopPropagation();
}

function onDragDrop(event: DragEvent) {
  if (props.disabled) return;
  event.preventDefault();
  checkAndProcessDroppedFiles(event);
  showDroppingView.value = false;
}

async function getViolatedRule(files: File[]) {
  for (const rule of props.rules ?? []) {
    const message = await rule(files);
    if (message) return message;
  }

  return null;
}

async function checkAndProcessDroppedFiles(event: DragEvent) {
  const files = Array.from(event.dataTransfer?.files ?? []).filter(onlyTruthys);
  const violatedRule = await getViolatedRule(files);

  if (violatedRule) return showViolatedRuleModal(violatedRule);
  if (files.length > 0) emit("filesdropped", files);
}

function showViolatedRuleModal(message: string) {
  app?.prompt("There are some issues with your files", {
    detail: message,
    buttons: {
      acknowledge: {
        buttonType: "secondary",
        buttonText: "Okay",
      },
    },
  });
}
</script>
